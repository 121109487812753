
  import dayjs from 'dayjs'
  import Component from 'vue-class-component'
  import { mapActions } from 'vuex'
  import { filters } from './filters'
  import { filters as publicFilters } from '@/components/dashboards/filters'
  import { GForm } from '@/components/forms/GForm'
  import PeriodPurchases from '../../PeriodPurchases.vue'
  import PeriodSales from '../../PeriodSales.vue'
  import PeriodCredits from '../../PeriodCredits.vue'
  import UtilityPeriodSales from '../../UtilityPeriodSales.vue'
  import UtilityPeriodCredits from '../../UtilityPeriodCredits.vue'
  import UtilityPeriodTotal from '../../UtilityPeriodTotal.vue'
  import { Watch } from 'vue-property-decorator'
  import Accumulateds from '../../Accumulateds.vue'
  import DetailCreditsByCar from '../../DetailCreditsByCar.vue'
  import DetailSalesByCar from '../../DetailSalesByCar.vue'
  import DetailPurchasesByCar from '../../DetailPurchasesByCar.vue'
  import CreditsByExecutive from '../../CreditsByExecutive.vue'
  import SalesByExecutive from '../../SalesByExecutive.vue'
  import PurchasesByExecutive from '../../PurchasesByExecutive.vue'
  import SalesReport from '../../Reports/Sales.vue'
  import ReserveReport from '../../Reports/Reserve.vue'
  import CreditsReport from '../../Reports/Credits.vue'
  import PurchasesReport from '../../Reports/Purchases.vue'
  import InspectionsReport from '../../Reports/Inspections.vue'
  import FinancialEvaluationReport from '../../Reports/FinancialEvaluation.vue'
  import AccumulatedInspections from '../../Inspections/AccumulatedInspections.vue'
  import Finished from '../../InspectionStatus/Finished.vue'
  import Canceled from '../../InspectionStatus/Canceled.vue'
  import Scheduled from '../../InspectionStatus/Scheduled.vue'
  import LeadsByTask from '@/components/dashboards/LeadsByTask.vue'
  import LoanApplications from '../../LoanApplications.vue'
  import SignedLoans from '../../SignedLoans.vue'
  import DealClosingReasonTreeMap from '../../DealClosingReasonTreeMap.vue'

@Component({
  components: {
    PeriodPurchases,
    PeriodSales,
    PeriodCredits,
    UtilityPeriodSales,
    UtilityPeriodCredits,
    UtilityPeriodTotal,
    Accumulateds,
    DetailCreditsByCar,
    DetailSalesByCar,
    DetailPurchasesByCar,
    CreditsByExecutive,
    SalesByExecutive,
    PurchasesByExecutive,
    SalesReport,
    ReserveReport,
    CreditsReport,
    PurchasesReport,
    InspectionsReport,
    FinancialEvaluationReport,
    AccumulatedInspections,
    Finished,
    Canceled,
    Scheduled,
    LeadsByTask,
    LoanApplications,
    SignedLoans,
    DealClosingReasonTreeMap,
  },
  methods: {
    ...mapActions('dashboard', [
      'getPurchasesAmount',
      'getPurchasesTarget',
      'getSalesByDay',
      'getSalesAmount',
      'getSalesTarget',
      'getCreditsByDay',
      'getCreditsTarget',
      'getUtilityCreditsByDay',
      'getStockStatus',
      'getStockPrices',
      'getExecutivesForReports',
      'getFinancialReports',
      'getInspectionReports',
      'getReserveReports',
      'getSalesReports',
      'getCreditsReports',
      'getPurchasesReports',
      'getInspectionsStatus',
      'getInspectionsSettings',
      'getSignedLoans',
      'getFinancing',
      'getSalesData',
      'getClosingReason',
    ]),
  },
})
  export default class StaffDashboard extends GForm {
  menu = false
  loading = false
  currentDate = dayjs()
  endOfMonth = this.currentDate.endOf('month')
  daysTillMonthEnd = this.endOfMonth
    .diff(this.currentDate, 'days')

  monthLenght = this.endOfMonth
    .diff(this.currentDate.startOf('month'), 'days') + 1

  monthPercentage = Math.round((this.currentDate.$D / this.monthLenght) * 100)
  selected = new Date(this.currentDate.format('YYYY/MM/DD')).toISOString().substring(0, 7)
  chartData: Record<string, any> = {}
  prevMonthData: Record<string, any> = {}
  publicChartData: Record<string, any> = {}

  getPurchasesAmount!: (variable) => Promise<Record<string, any>>
  getPurchasesTarget!: () => Promise<Record<string, any>>
  getSalesByDay!: (variable) => Promise<Record<string, any>>
  getSalesTarget!: () => Promise<Record<string, any>>
  getCreditsByDay!: (variable) => Promise<Record<string, any>>
  getCreditsTarget!: () => Promise<Record<string, any>>
  getExecutivesForReports!: () => Promise<Record<string, any>>
  getFinancialReports!: () => Promise<Record<string, any>>
  getInspectionReports!: () => Promise<Record<string, any>>
  getReserveReports!: () => Promise<Record<string, any>>
  getSalesReports!: () => Promise<Record<string, any>>
  getCreditsReports!: () => Promise<Record<string, any>>
  getPurchasesReports!: () => Promise<Record<string, any>>
  getInspectionsStatus!: (variable) => Promise<Record<string, any>>
  getInspectionsSettings!: () => Promise<Record<string, any>>
  getSignedLoans!: (variable) => Promise<Record<string, any>>
  getFinancing!: (variable) => Promise<Record<string, any>>
  getClosingReason!: (variable) => Promise<Record<string, any>>
  getSalesData!: (variable) => Promise<Record<string, any>>

  async mounted () {
    this.chartData.executivesForReports = await this.getExecutivesForReports()
  }

  // Closing reason data
  calledClosingReasonData = false

  async getClosingReasonData (_entries?, _observer?, isIntersecting?) {
    if (!isIntersecting || this.calledClosingReasonData) {
      return
    }
    this.$set(this.chartData, 'closingReason', await this.getClosingReason(this.filters.closingReason))

    this.calledClosingReasonData = true
  }

  // Results data
  calledResults = false

  async getResultsData (_entries?, _observer?, isIntersecting?) {
    if (!isIntersecting || this.calledResults) {
      return
    }
    this.loading = true
    this.$set(this.chartData, 'purchasesAmount', await this.getPurchasesAmount(this.filters.stockCreatedDate))
    this.$set(this.chartData, 'purchasesTarget', await this.getPurchasesTarget())
    this.$set(this.chartData, 'sales', await this.getSalesByDay(this.filters.soldDate))

    // Filtra solo las ventas del ejecutivo
    if (this.chartData.sales?.sales_stock_aggregate) {
      this.$set(this.chartData.sales.sales_stock_aggregate, 'nodes',
        this.chartData.sales.sales_stock_aggregate?.nodes.filter(sale => sale.deals?.[0]?.lead?.executive.id === this.idExecutive)
      )
    }

    this.$set(this.chartData, 'salesTarget', await this.getSalesTarget())
    this.$set(this.chartData, 'salesData', await this.getSalesData(this.filters.sales))
    this.$set(this.chartData, 'credits', await this.getCreditsByDay(this.filters.credit))
    this.$set(this.chartData, 'creditTarget', await this.getCreditsTarget())

    this.$set(this.publicChartData, 'purchasesAmount', await this.getPurchasesAmount(this.filters.stockCreatedDate))
    this.$set(this.publicChartData, 'sales', await this.getSalesByDay(this.publicFilters.soldDate))
    this.$set(this.publicChartData, 'salesData', await this.getSalesData(this.publicFilters.sales))
    this.$set(this.publicChartData, 'purchasesAmount', await this.getPurchasesAmount(this.publicFilters.stockCreatedDate))
    this.$set(this.publicChartData, 'credits', await this.getCreditsByDay(this.publicFilters.credit))

    this.loading = false

    this.calledResults = true
  }

  calledAccumulateds = false

  async getAccumulateds (_entries?, _observer?, isIntersecting?) {
    if (!isIntersecting || this.calledAccumulateds) {
      return
    }
    this.$set(this.prevMonthData, 'sales', await this.getSalesByDay(this.prevMonthFilters.soldDate))

    // Filtra solo las ventas del ejecutivo
    if (this.prevMonthData.sales?.sales_stock_aggregate) {
      this.$set(this.prevMonthData.sales.sales_stock_aggregate, 'nodes',
        this.prevMonthData.sales.sales_stock_aggregate?.nodes.filter(sale => sale.deals?.[0]?.lead?.executive.id === this.idExecutive)
      )
    }

    this.$set(this.prevMonthData, 'purchases', await this.getPurchasesAmount(this.prevMonthFilters.stockCreatedDate))
    this.$set(this.prevMonthData, 'credits', await this.getCreditsByDay(this.prevMonthFilters.credit))

    this.calledAccumulateds = true
  }

  calledDetailedData = false

  async getDetailedData (_entries?, _observer?, isIntersecting?) {
    if (!isIntersecting || this.calledDetailedData) {
      return
    }
    this.$set(this.chartData, 'executivesForReports', await this.getExecutivesForReports())

    this.calledDetailedData = true
  }

  get publicFilters () {
    return publicFilters(this.currentMonth)
  }

  calledReports = false

  async getReportsData (_entries?, _observer?, isIntersecting?) {
    if (!isIntersecting || this.calledReports) {
      return
    }
    this.$set(this.chartData, 'reports', await this.getFinancialReports())
    this.$set(this.chartData, 'inspectionsReports', await this.getInspectionReports())
    this.$set(this.chartData, 'reserveReports', await this.getReserveReports())
    this.$set(this.chartData, 'salesReports', await this.getSalesReports())
    this.$set(this.chartData, 'creditsReports', await this.getCreditsReports())
    this.$set(this.chartData, 'purchasesReports', await this.getPurchasesReports())

    this.calledReports = true
  }

  calledFinancing = false

  async getFinancingData (_entries?, _observer?, isIntersecting?) {
    if (!isIntersecting || this.calledFinancing) {
      return
    }
    this.$set(this.chartData, 'signedLoans', await this.getSignedLoans(this.filters.signedLoans))
    this.$set(this.chartData, 'evaluations', await this.getFinancing(this.filters.loanApplications))

    this.calledFinancing = true
  }

  calledInspectionsData = false

  async getInspectionsData (_entries?, _observer?, isIntersecting?) {
    if (!isIntersecting || this.calledInspectionsData) {
      return
    }
    this.$set(this.chartData, 'inspectionsStatus', await this.getInspectionsStatus(this.filters.inspectionsStatus))
    this.$set(this.prevMonthData, 'inspectionsStatus', await this.getInspectionsStatus(this.prevMonthFilters.inspectionsStatus))
    this.$set(this.chartData, 'inspectionsSettings', await this.getInspectionsSettings())

    this.calledInspectionsData = true
  }

  formatDate (date, format = 'YYYY-MM-DDTHH:mm:ss') {
    if (!date?.isValid()) return

    const local = dayjs(date)
    const offset = -dayjs().utcOffset()
    const localDate = local.add(offset, 'minute')

    return localDate.format(format)
  }

  get filters () {
    return filters(this.currentMonth, this.idExecutive)
  }

  get prevMonthFilters () {
    return filters(this.prevMonth, this.idExecutive)
  }

  get idExecutive () {
    return this.idEmployee || 1
  }

  get formData () {
    return {
      start: this.formatDate(dayjs(this.selected).startOf('month'), 'YYYY-MM-DDTHH:mm:ss'),
      end: this.formatDate(dayjs(this.selected).endOf('month'), 'YYYY-MM-DDTHH:mm:ss'),
    }
  }

  get currentMonth () {
    return {
      _gte: dayjs(this.formData.start).format('YYYY-MM-DDTHH:mm:ss'),
      _lte: dayjs(this.formData.end).format('YYYY-MM-DDTHH:mm:ss'),
    }
  }

  get prevMonth () {
    const startPrevMonth = dayjs(this.formData.start).subtract(1, 'month')
    const endPrevMonth = startPrevMonth.endOf('month')

    return {
      _gte: startPrevMonth.format('YYYY-MM-DDTHH:mm:ss'),
      _lte: this.formatDate(endPrevMonth.endOf('day')),
    }
  }

  get dateString () {
    const day = this.currentDate.format('DD')
    const monthNumber = this.currentDate.format('MM')
    let month
    switch (monthNumber) {
      case '01':
        month = 'Enero'
        break
      case '02':
        month = 'Febrero'
        break
      case '03':
        month = 'Marzo'
        break
      case '04':
        month = 'Abril'
        break
      case '05':
        month = 'Mayo'
        break
      case '06':
        month = 'Junio'
        break
      case '07':
        month = 'Julio'
        break
      case '08':
        month = 'Agosto'
        break
      case '09':
        month = 'Septiembre'
        break
      case '10':
        month = 'Octubre'
        break
      case '11':
        month = 'Noviembre'
        break
      default:
        month = 'Diciembre'
        break
    }
    return `${day} de ${month}`
  }

  @Watch('currentMonth', { immediate: true, deep: true })
  @Watch('idExecutive', { immediate: true, deep: true })
  async onDateChange () {
    this.calledResults = false
    this.calledAccumulateds = false
    this.calledDetailedData = false
    this.calledReports = false
    this.calledInspectionsData = false
    this.calledFinancing = false
    this.calledClosingReasonData = false
    // TODO: Uncomment when the corresponding section is added
    // this.calledReports = false
    // this.calledAccumulateds = false
    // this.calledStockData = false
    // this.calledVehicleData = false
    // this.calledStockQuantity = false
    // this.calledLeadsData = false
    // this.calledClosingReasonData = false
    // this.calledDetailedData = false

    await this.getResultsData(undefined, undefined, true)
  }
  }
